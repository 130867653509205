import logo from "./logo.svg";
import "./App.css";

const apps = [
  {
    title: "News & Weather APP",
    url: "https://news-and-weather-app.onrender.com",
    tech: "Python, OpenAI Chat API, Function Calling, Tivaly Client, Streamlit",
    img: "search-news.gif",
    description:
      "This is a ChatGPT-like AI Agent & Weather APP. It can chat with you and tell about the latest news.",
    version: "v1.0",
  },
  {
    title: "AI Agent & Weather APP",
    url: "https://ai-agent-with-chatmodel-and.onrender.com",
    tech: "Python, OpenAI Chat API, Function Calling, Streamlit",
    img: "weather-app.gif",
    description:
      "This is a ChatGPT-like AI Agent & Weather APP. It can chat and tell you the weather of any city",
    version: "v1.0",
  },

  {
    title: "Custom AI-Agent",
    url: "https://custom-chatbot-c4kq.onrender.com",
    tech: "Python, OpenAI Chat API, Knowledge Retrieval (RAG), Streamlit",
    img: "knowledge-retrieval.gif",
    description:
      "This is a ChatGPT-like AI Agent that can chat and answer your user queries about custom data",
    version: "v1.0",
  },
];

function Card({ title, url, tech, img, description, version }) {
  return (
    <div className="col-lg-11 col-xl-9 col-xxl-8">
      <div className="card overflow-hidden shadow rounded-4 border-0 mb-5">
        <div className="card-body p-0">
          <div className="d-flex align-items-center">
            <div className="px-5">
              <h4 className="fw-bolder mt-2 text-primary">{title}</h4>
              <div className="mb-4">
                <p>{description}</p>
                <a
                  className="btn btn-primary float-end"
                  href={url}
                  target="_blank"
                >
                  checkout
                </a>
              </div>
            </div>
            <a href={url} target="_blank">
              <img
                className="img-fluid rounded-3 ms-auto"
                src={require(`./img/${img}`)}
                alt="..."
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

function App() {
  return (
    <>
      <main className="flex-shrink-0">
        <nav classNameName="navbar navbar-expand-lg navbar-light bg-white py-3">
          <div className="container px-5">
            <a className="navbar-brand" href="index.html">
              {/* <span className="fw-bolder text-primary">Start Bootstrap</span> */}
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav ms-auto mb-2 mb-lg-0 small fw-bolder">
                <li className="nav-item">
                  <a className="nav-link" href="index.html">
                    Home
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="resume.html">
                    Resume
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="projects.html">
                    Projects
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="contact.html">
                    Contact
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
        <section className="px-3">
          <div className="container px-5">
            <div className="text-center">
              <h1 className="display-5 fw-bolder mb-5">
                <span className="text-gradient d-inline">Projects</span>
              </h1>
            </div>
            <div className="row gx-5 justify-content-center">
              {apps.map((app) => (
                <Card {...app} />
              ))}
            </div>
          </div>
        </section>
      </main>
      <footer className="bg-white py-4 mt-auto">
        <div className="container px-5">
          <div className="row align-items-center justify-content-between flex-column flex-sm-row">
            <div className="col-auto">
              <div className="small m-0">Copyright &copy; SandyLTech 2023</div>
            </div>
            {/* <div className="col-auto">
              <a className="small" href="#!">
                Privacy
              </a>
              <span className="mx-1">&middot;</span>
              <a className="small" href="#!">
                Terms
              </a>
              <span className="mx-1">&middot;</span>
              <a className="small" href="#!">
                Contact
              </a>
            </div> */}
          </div>
        </div>
      </footer>
    </>
  );
}

export default App;
